"use client"

import { useState, useEffect, useRef } from "react"
import { YMaps, Map, Placemark, ZoomControl, FullscreenControl, GeolocationControl } from "@pbe/react-yandex-maps"
import { Landmark, Search, X, ChevronRight, Phone, MapPin, Clock, Menu, Store, PenToolIcon, Car } from "lucide-react"

import { mapShop } from "../../services/shopService"
import classes from "./goMap.module.css"

const GoMap = () => {
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userLocation, setUserLocation] = useState(null)
  const [activeCategory, setActiveCategory] = useState("all")
  const mapRef = useRef(null)
  const ymaps = useRef(null)

  useEffect(() => {
    const getLocations = async () => {
      try {
        setIsLoading(true)
        const response = await mapShop()
        const allLocations = response?.data || []
        setLocations(allLocations)
        setSelectedLocation(allLocations[0])
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }

    getLocations()
    getUserLocation()
  }, [])

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation([position.coords.latitude, position.coords.longitude])
      })
    }
  }

  const handleMapClick = (location) => {
    setSelectedLocation(location)
    centerMapOnLocation(location)
    setIsModalOpen(true)
  }

  const centerMapOnLocation = (location) => {
    if (mapRef.current && location?.shop_information && location?.shop_information[0]) {
      const latitude = Number.parseFloat(location.shop_information[0].latitude)
      const longitude = Number.parseFloat(location.shop_information[0].longitude)
      if (!isNaN(latitude) && !isNaN(longitude)) {
        mapRef.current.setCenter([latitude, longitude], 15, { duration:500 })
      }
    }
  }

  const handleLocationItemClick = (location) => {
    setSelectedLocation(location)
    centerMapOnLocation(location)
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false)
    }
  }

  const handleCallClick = () => {
    if (selectedLocation?.shop_information[0]?.phone1) {
      window.location.href = `tel:${selectedLocation.shop_information[0].phone1}`
    }
  }

  const filteredLocations = locations.filter(
    (location) =>
      (activeCategory === "all" || Number(location.shop_type) === Number(activeCategory)) &&
      (location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.shop_information[0]?.address.toLowerCase().includes(searchTerm.toLowerCase())),
  )
  
  

  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/`

  const handleWazeNavigation = () => {
    if (!selectedLocation || !selectedLocation.shop_information[0]) return

    const latitude = selectedLocation.shop_information[0].latitude
    const longitude = selectedLocation.shop_information[0].longitude

    const wazeUrl = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`

    window.open(wazeUrl, "_blank")
  }

  const getMarkerOptions = (locationType) => {
    switch (locationType) {
      case 2: 
        return { preset: "islands#blueShoppingIcon" }
      case 3: 
        return { preset: "islands#orangeAutoIcon" }
      case 1: 
        return { preset: "islands#greenAutoIcon" }
      default:
        return { preset: "islands#blueCircleDotIcon" }
    }
  }

  return (
    <div className={classes.mapContainer}>
      <YMaps query={{ apikey: "879a3590-ccee-42bf-bd5d-4d336e4ad116", lang: "az" }}>
        <Map
        className={classes.map}
          width={"100%"}
          height={"100%"}
          defaultState={{
            center: [40.41043107313508, 49.8225385944722],
            zoom: 12,
          }}
          options={{ suppressMapOpenBlock: true }}
          instanceRef={mapRef}
          onLoad={(ymapsInstance) => {
            ymaps.current = ymapsInstance
          }}
          modules={["control.ZoomControl", "control.FullscreenControl", "route"]}
        >
          <ZoomControl options={{ float: "right" }} />
          <FullscreenControl />
          <GeolocationControl options={{ float: "left" }} />
          {filteredLocations.map((location, index) => {
            if (location?.shop_information && location?.shop_information[0]) {
              const latitude = Number.parseFloat(location?.shop_information[0]?.latitude)
              const longitude = Number.parseFloat(location?.shop_information[0]?.longitude)

              if (!isNaN(latitude) && !isNaN(longitude)) {
                const isSelected = selectedLocation?.id === location.id

                return (
                  <Placemark
                    key={index}
                    geometry={[latitude, longitude]}
                    properties={{
                      balloonContent: location?.name,
                      iconCaption: location?.name,
                    }}
                    options={{
                      ...getMarkerOptions(location.shop_type),
                      iconColor: isSelected ? "#FF4136" : undefined,
                    }}
                    onClick={() => handleMapClick(location)}
                  />
                )
              }
            }
            return null
          })}
          {userLocation && (
            <Placemark
              geometry={userLocation}
              options={{
                preset: "islands#geolocationIcon",
                iconColor: "#3b5998",
              }}
            />
          )}
        </Map>
      </YMaps>

      <button className={classes.mobileMenuButton} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <MapPin />
      </button>

      <div className={`${classes.sidebar} ${isSidebarOpen ? classes.open : ""}`}>
        <button className={classes.sidebarToggle} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <ChevronRight />
        </button>
        <div className={classes.sidebarContent}>
          <div className={classes.categoryButtons}>
            <button
              className={`${classes.categoryButton} ${activeCategory === "all" ? classes.active : ""}`}
              onClick={() => setActiveCategory("all")}
            >
              Hamısı
            </button>
            <button
              className={`${classes.categoryButton} ${activeCategory === 2 ? classes.active : ""}`}
              onClick={() => setActiveCategory(2)}
            >
              <Store size={16} /> Mağaza
            </button>
            <button
              className={`${classes.categoryButton} ${activeCategory === 3 ? classes.active : ""}`}
              onClick={() => setActiveCategory(3)}
            >
              <Car size={16} /> Avtosalon
            </button>
            <button
              className={`${classes.categoryButton} ${activeCategory === 1 ? classes.active : ""}`}
              onClick={() => setActiveCategory(1)}
            >
              <Landmark size={16} /> Rəsmi Diler
            </button>
          </div>
          <div className={classes.searchContainer}>
            <Search className={classes.searchIcon} />
            <input
              type="text"
              placeholder="Axtar..."
              className={classes.searchInput}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={classes.locationList}>
            {filteredLocations.map((location, index) => (
              <div
                key={index}
                className={`${classes.locationItem} ${selectedLocation?.id === location.id ? classes.active : ""}`}
                onClick={() => handleLocationItemClick(location)}
              >
                <h3>{location.name}</h3>
                <p>{location.shop_information[0]?.address}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isModalOpen && selectedLocation && (
        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <button className={classes.closeModal} onClick={() => setIsModalOpen(false)}>
              <X />
            </button>
            <img
              src={
                selectedLocation.cover ? image_url + selectedLocation.cover : "/placeholder.svg?height=200&width=400"
              }
              alt={selectedLocation.name}
              className={classes.locationImage}
            />
            <h2>{selectedLocation.name}</h2>
            <div className={classes.locationDetails}>
              <div className={classes.detailItem}>
                <Phone />
                <p>
                  {selectedLocation.shop_information[0]?.phone1 ?? ""}
                  {selectedLocation.shop_information[0]?.phone2 && ` / ${selectedLocation.shop_information[0]?.phone2}`}
                </p>
              </div>
              <div className={classes.detailItem}>
                <MapPin />
                <p>{selectedLocation.shop_information[0]?.address}</p>
              </div>
              <div className={classes.detailItem}>
                <Clock />
                <p>09:00 - 18:00</p>
              </div>
            </div>
            <div className={classes.actionButtons}>
              <button className={classes.directionButton} onClick={handleWazeNavigation}>
                Waze ilə istiqamət al
              </button>
              <button className={classes.callButton} onClick={handleCallClick}>
                Zəng et
              </button>
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className={classes.loadingOverlay}>
          <div className={classes.spinner}></div>
          <p>Yüklənir...</p>
        </div>
      )}
    </div>
  )
}

export default GoMap

